import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import graph from "../../images/topic-2/overview/graph.svg"
import graph_fr from "../../images/topic-2/overview/graph_fr.svg"
import step1 from "../../images/topic-2/overview/section-1.jpg"
import step2 from "../../images/topic-2/overview/section-2.jpg"
import step3 from "../../images/topic-2/overview/section-3.jpg"
import step4 from "../../images/topic-2/overview/section-4.jpg"
import BottomNav from "../../components/bottomNav"
import MyBreadcrumb from "../../components/myBreadcrumb"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import MyBullets from "../../components/myBullets"
import LinkSteps from "../../components/linkSteps"
import parse from "html-react-parser"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const LogGrowth = ({ location }) => {
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()

  const pageContent = {
    breadcrumb: parse(t("Topic2_Overview_breadcrumb")),
    title: parse(t("Topic2_Overview_title")),
    topText: parse(t("Topic2_Overview_topText")),
    images: [
      {
        src: language == "en" ? graph : graph_fr,
        alt: "",
        caption: parse(t("Topic2_Overview_images[0].caption")),
      },
      {
        src: step1,
        alt: "",
        caption: parse(t("Topic2_Overview_images[1].caption")),
      },
      {
        src: step2,
        alt: "",
        caption: parse(t("Topic2_Overview_images[2].caption")),
      },
      {
        src: step3,
        alt: "",
        caption: parse(t("Topic2_Overview_images[3].caption")),
      },
      {
        src: step4,
        alt: "",
        caption: parse(t("Topic2_Overview_images[4].caption")),
      },
    ],
    middleText: [
      parse(t("Topic2_Overview_middleText[0]")),
      parse(t("Topic2_Overview_middleText[1]")),
    ],
    bottomText: parse(t("Topic2_Overview_bottomText")),
    instructions: parse(t("Topic2_Overview_instructions")),
  }
  // =========================================================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic2_Overview_pageTitle"))} />

      <MyBreadcrumb
        topicTitle=""
        secondTitle=""
        currentTitle={pageContent.breadcrumb}
      />

      <div className="homeContent">
        <Row>
          <Col sm="8">
            <TopHeader
              title={pageContent.title}
              content={pageContent.topText}
            />
          </Col>
          <Col sm="4">
            <img
              src={pageContent.images[0].src}
              alt={pageContent.images[0].alt}
              className="roundMe"
              style={{ maxWidth: `290px`, marginBottom: `1.45rem` }}
            />
            <p className="smText">{pageContent.images[0].caption}</p>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="12">
            <MyBullets
              title={pageContent.middleText[0]}
              // titleColor="#990003"
              listContent={pageContent.middleText[1]}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="8">
            {pageContent.bottomText}
          </Col>
        </Row>

        <Instructions content={pageContent.instructions} />

        <LinkSteps
          // titleColor="#990003"
          data={[
            {
              id: "section1",
              text: pageContent.images[1].caption,
              image: pageContent.images[1].src,
              alt: pageContent.images[1].alt,
            },
            {
              id: "section2",
              text: pageContent.images[2].caption,
              image: pageContent.images[2].src,
              alt: pageContent.images[2].alt,
            },
            {
              id: "section3",
              text: pageContent.images[3].caption,
              image: pageContent.images[3].src,
              alt: pageContent.images[3].alt,
            },
            {
              id: "section4",
              text: pageContent.images[4].caption,
              image: pageContent.images[4].src,
              alt: pageContent.images[4].alt,
            },
          ]}
        />

        <hr />
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              prevName={parse(t("Home"))}
              btnLinkPrev="/"
              nextName={parse(t("Next"))}
              btnLinkNext="/logisticgrowth/section1"
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default LogGrowth
