import React from "react"

const MyBullets = ({ title, titleColor, listContent }) => {
  return (
    <div>
      <h4 style={{ color: titleColor }}>{title}</h4>
      <ul>{listContent}</ul>
    </div>
  )
}

export default MyBullets
